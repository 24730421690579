/*
  elm-hot creates an additional div wrapper around the app to make HMR possible.
  This could break styling in development mode if you are using Elm UI.

  More context in the issue:
    https://github.com/halfzebra/create-elm-app/issues/320
*/
[data-elm-hot="true"] {
  height: inherit;
}
.dropdown-item {
  cursor: pointer;
}
.error-container {
  /* https://stackoverflow.com/questions/1719452/how-to-make-a-div-always-full-screen */
  position: fixed !important;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
}
.zeropoly {
  color: grey;
}
h3,
.statistics .name,
.upgrade-header,
.theme-dropdown button,
.legend,
.conflict .list-label,
.mobile-tab-name,
.sidebar td {
  text-transform: capitalize;
}
.progress .progress-bar {
  transition: none;
}
.lang-backwards *, .lang-backwards {
  direction: rtl;
  unicode-bidi: bidi-override;
}
.lang-upside-down {
  display: inline-block;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.achievement-toast-container {
  position: fixed !important;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.achievement-show, .achievement-hide {
  transition: opacity 1s linear;
}
.achievement-show {
  opacity: 1;
}
.achievement-hide {
  opacity: 0;
}
.achievement-toast .body,
.achievements .body {
  text-align: center;
}
.achievement-toast .body h3,
.achievements .body h3 {
  font-weight: bold;
}
.achievement-toast .icon,
.achievements .icon {
  font-size: 400%;
  font-weight: bold;
  text-align: left;
}
.achievement-toast .points,
.achievements .points {
  font-size: 400%;
  font-weight: bold;
  text-align: right;
}
.achievement-toast .close {
  font-size: 100%;
  font-weight: bold;
  text-align: right;
}
.debug-enable {
  opacity: 0;
}
.debug-enable:hover {
  opacity: 0.20;
}
.progress-bar {
  overflow-x: visible;
  white-space: nowrap;
  padding-left: 1px;
  /* outline. http://css-tricks.com/adding-stroke-to-web-text/ */
  /* text-shadow: 1px 1px $brand-primary, -1px 1px $brand-primary, 1px -1px $brand-primary, -1px -1px $brand-primary; */

  color: white;
  text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 1px 0px black, 0px 1px black, -1px 0px black, 0px -1px black;

  /* color: black !important; */
  /* text-shadow: 1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white, 1px 0px white, 0px 1px white, -1px 0px white, 0px -1px white; */

  /* text-stroke: 1px black; */
  /* -webkit-text-stroke: 1px black; */
}
.progress {
  /* font-weight: bold; */
  height: 1.25rem !important;
  font-size: 1rem !important;
}
.progress-bar a,
.progress-bar a:hover {
  color: inherit;
  text-decoration: underline;
}
/* https://www.w3schools.com/howto/howto_css_bullet_color.asp */
ul.legend {
  list-style: none;
}
ul.legend li .dot {
  font-weight: bold;
}
/* ul.legend li.highlighted .dot { */
  /* font-size: 120%; */
/* } */
/* svg .highlighted { */
  /* transform: scale(1.1); */
/* } */
ul.legend li.entry-0 .dot {
  color: blue;
}
svg .pie-0 {
  fill: blue;
}
ul.legend li.entry-1 .dot {
  color: green;
}
svg .pie-1 {
  fill: green;
}
ul.legend li.entry-2 .dot {
  color: red;
}
svg .pie-2 {
  fill: red;
}
ul.legend li.entry-3 .dot {
  color: yellow;
}
svg .pie-3 {
  fill: yellow;
}
ul.legend li.entry-4 .dot {
  color: purple;
}
svg .pie-4 {
  fill: purple;
}
ul.legend li.entry-5 .dot {
  color: orange;
}
svg .pie-5 {
  fill: orange;
}
ul.legend li.entry-6 .dot {
  color: cyan;
}
svg .pie-6 {
  fill: cyan;
}
ul.legend li.entry-7 .dot {
  color: pink;
}
svg .pie-7 {
  fill: pink;
}
.environment-label {
  position: absolute;
  top: 0;
  left: 0;
}
.img-icon {
  height: 1em;
}
table.flags td,
table.flags th {
  border: 0.2em inset;
}
table.flags .active {
  color: green;
}
table.flags .inactive {
  color: red;
}
table.flags .empty {
  color: grey;
}
.keycombo .modal-content {
  max-height: 90vh;
}
.keycombo .modal-body {
  overflow: auto;
}
.table.sidebar.tab {
  width: auto;
}
.table.sidebar.tab tr {
  cursor: pointer;
}
/*
.table.sidebar.tab .count,
.table.sidebar.tab .velocity {
  text-align: right;
}
*/
.container.header {
  overflow-x: hidden;
}
.mobile-tab-nav {
  font-size: 50%;
  overflow: hidden;
}
.mobile-tab-nav .nav-link {
  padding: 0;
}
table td.upgradable-icon {
  padding-right: 0;
  min-width: 2.2em;
}
table td.item-name {
  padding-left: 0;
}
